import React from "react"
import Prize from "src/components/prize"

const Prizing = ({ content }) => {
  return (
    <section className="prizing">
      <div className="container">

        <h2>{content.title}</h2>
        <p>{content.copy}</p>

        <div className="prizes-container">

          {'prizesSection' in content && content.prizesSection && content.prizesSection.map((section, i) => (
            <div className="prizes-section" key={i}>
              <h3>{section.title}</h3>
              <div className="prizes">
                {section.prizes && section.prizes.map((prize, i) => (
                  <Prize key={i} prize={prize} />
                ))}
              </div>
            </div>
          ))}

          {'grandPrizesTitle' in content && (
            <div className="grand-prizes">
              <h3>{content.grandPrizesTitle}</h3>
              <div className="prizes">
                {content.grandPrizes && content.grandPrizes.map((prize, i) => (
                  <Prize key={i} prize={prize} />
                ))}
              </div>
            </div>
          )}

          {'runnerUpPrizesTitle' in content && (
            <div className="other-prizes">
              <div className="runner-up">
                <h3>{content.runnerUpPrizesTitle}</h3>
                <div className="prizes">
                  {content.runnerUpPrizes && content.runnerUpPrizes.map((prize, i) => (
                    <Prize key={i} prize={prize} />
                  ))}
                </div>
              </div>

              <div className="honorable-mentions">
                <h3>{content.honorableMentionsTitle}</h3>
                <div className="prizes">
                  {content.honorableMentions && content.honorableMentions.map((prize, i) => (
                    <Prize key={i} prize={prize} />
                  ))}
                </div>
              </div>
            </div>
          )}

        </div>

      </div>
    </section>
  )
}

export default Prizing
