import React from "react"

const Prize = ({ prize }) => {
  return (
    <div className="prize">
      <img src={prize.image} loading="lazy" alt="prize" />
      <div className="name">{prize.name}</div>
    </div>
  )
}

export default Prize
