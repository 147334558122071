import React from "react"

const Booth = ({ content, type }) => {
  if (!content.image) {
    return ''
  }
  return (
    <section className={`booth ${type}`}>
      <h2>{content.title}</h2>
      <img src={content.image} loading="lazy" alt="booth" />
      <div className="content">
        <p>{content.copy}</p>
        {content.joinNowText && <a href={content.joinNowURL} className="box" target="_blank" rel="noreferrer">{content.joinNowText}</a>}
      </div>
    </section>
  )
}

export default Booth
