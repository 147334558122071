import React, { useEffect, useRef } from "react"
import { useStore } from "src/state"

const GameHero = ({ content, section }) => {
  const { setCurrentSection } = useStore()
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries[0] && entries[0].isIntersecting && setCurrentSection(section)
    }, { threshold: 0.6 })
    const currentRef = ref.current
    currentRef && observer.observe(currentRef)
    return () => (currentRef && observer.unobserve(currentRef))
  }, [ref, setCurrentSection, section])

  return (
    <section className={`game-hero ${section}`} ref={ref}>
      <img src={content.image} alt="zepeto hero" className="hero-image" loading="lazy" />
      <div className="content">
        {content.comingSoon && <div className="coming-soon">{content.comingSoon}</div>}
        <h2>{content.title}</h2>
        <p>{content.copy}</p>
        <p>{content.dateDetails}</p>
        {content.learnMoreURL && <a href={content.learnMoreURL} className="box" target="_blank" rel="noreferrer">{content.learnMoreText}</a>}
        {(content.appStoreUrl || content.playStoreUrl) && (
          <div className="stores">
            {content.appStoreUrl && <a href={content.appStoreUrl} target="_blank" rel="noreferrer">
              <img src="/img/app-store.png" loading="lazy" alt="app store" />
            </a>}
            {content.playStoreUrl && <a href={content.playStoreUrl} target="_blank" rel="noreferrer">
              <img src="/img/play-store.png" loading="lazy" alt="play store" />
            </a>}
          </div>
        )}
      </div>
    </section>
  )
}

export default GameHero
