import React from "react"

const SocialLink = ({ content }) => {

  return (
    <a href={content.url} target="_blank" rel="noreferrer">
      {content.platform === 'youtube' && (
        <svg width="27" height="21" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-target" d="M25.7653 2.81639C25.4709 1.7078 24.6034 0.834703 23.502 0.538406C21.5056 0 13.5002 0 13.5002 0C13.5002 0 5.4949 0 3.49845 0.538406C2.39702 0.83475 1.52955 1.7078 1.23513 2.81639C0.700195 4.82578 0.700195 9.01819 0.700195 9.01819C0.700195 9.01819 0.700195 13.2106 1.23513 15.22C1.52955 16.3286 2.39702 17.1653 3.49845 17.4616C5.4949 18 13.5002 18 13.5002 18C13.5002 18 21.5055 18 23.502 17.4616C24.6034 17.1653 25.4709 16.3286 25.7653 15.22C26.3002 13.2106 26.3002 9.01819 26.3002 9.01819C26.3002 9.01819 26.3002 4.82578 25.7653 2.81639ZM10.882 12.8246V5.2118L17.5729 9.01828L10.882 12.8246Z" fill="black"></path>
        </svg>
      )}
      {content.platform === 'kuaishou' && (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 138.61 158.07"><path d="M425.58,386a27.64,27.64,0,0,1,27.72,27.56v21.89A27.64,27.64,0,0,1,425.58,463H384.82a27.72,27.72,0,0,1-26.35-19l-24.29,12.1a13.49,13.49,0,0,1-18.06-6,13.31,13.31,0,0,1-1.42-6V404.95A13.47,13.47,0,0,1,334.22,393l24.18,12.17A27.72,27.72,0,0,1,384.82,386h40.77Zm-.17,14.19H385.23a13.82,13.82,0,0,0-13.86,13.55v21.3A13.82,13.82,0,0,0,385,448.85h40.18A13.82,13.82,0,0,0,439,435.3V414A13.82,13.82,0,0,0,425.41,400.21Zm-92.78,8.55a3.66,3.66,0,0,0-3.67,3.58V437a3.68,3.68,0,0,0,5.28,3.28l.06,0,22.8-11.56v-8.18l-22.82-11.38A3.69,3.69,0,0,0,332.63,408.76ZM366.47,305A37.53,37.53,0,0,1,399.21,324a32,32,0,1,1-4.14,42.34A37.41,37.41,0,1,1,366.47,305Zm0,15a22.29,22.29,0,1,0,22.42,22.29A22.35,22.35,0,0,0,366.47,320ZM421.1,330.5a17.07,17.07,0,0,0-17.12,17h0a17.07,17.07,0,0,0,17.12,17h0a17.07,17.07,0,0,0,17.12-17h0a17.07,17.07,0,0,0-17.12-17Z" transform="translate(-314.69 -304.96)" fill="black" /></svg>
      )}
      {content.platform === 'twitch' && (
        <svg width="22" height="22" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-target" d="M17.3362 4.85016H15.5254V9.99234H17.3362V4.85016ZM12.3595 4.82812H10.5487V9.97266H12.3595V4.82812ZM4.66402 0L0.139648 4.28531V19.7147H5.56918V24L10.094 19.7147H13.7151L21.8606 12V0H4.66402ZM20.0503 11.1445L16.4306 14.572H12.81L9.64121 17.572V14.572H5.56918V1.71469H20.0503V11.1445Z" fill="black"></path>
        </svg>
      )}
      {content.platform === 'instagram' && (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-target" d="M10.5648 5.6094C7.56655 5.6094 5.14812 8.01409 5.14812 10.9953C5.14812 13.9766 7.56655 16.3813 10.5648 16.3813C13.5631 16.3813 15.9815 13.9766 15.9815 10.9953C15.9815 8.01409 13.5631 5.6094 10.5648 5.6094ZM10.5648 14.4969C8.62726 14.4969 7.04326 12.9266 7.04326 10.9953C7.04326 9.06409 8.62255 7.49377 10.5648 7.49377C12.5071 7.49377 14.0864 9.06409 14.0864 10.9953C14.0864 12.9266 12.5024 14.4969 10.5648 14.4969ZM17.4665 5.38909C17.4665 6.08752 16.9008 6.64534 16.2031 6.64534C15.5007 6.64534 14.9397 6.08284 14.9397 5.38909C14.9397 4.69534 15.5054 4.13284 16.2031 4.13284C16.9008 4.13284 17.4665 4.69534 17.4665 5.38909ZM21.0541 6.66409C20.974 4.98127 20.5874 3.49065 19.3475 2.26252C18.1124 1.0344 16.6133 0.650024 14.9208 0.565649C13.1765 0.467212 7.9484 0.467212 6.20412 0.565649C4.5164 0.645337 3.01726 1.02971 1.7774 2.25784C0.537546 3.48596 0.155689 4.97659 0.070832 6.6594C-0.028168 8.39377 -0.028168 13.5922 0.070832 15.3266C0.150975 17.0094 0.537546 18.5 1.7774 19.7282C3.01726 20.9563 4.51169 21.3407 6.20412 21.425C7.9484 21.5235 13.1765 21.5235 14.9208 21.425C16.6133 21.3453 18.1124 20.961 19.3475 19.7282C20.5827 18.5 20.9693 17.0094 21.0541 15.3266C21.1531 13.5922 21.1531 8.39846 21.0541 6.66409ZM18.8007 17.1875C18.433 18.1063 17.7211 18.8141 16.7924 19.1844C15.4017 19.7328 12.1017 19.6063 10.5648 19.6063C9.02798 19.6063 5.72326 19.7282 4.33726 19.1844C3.41326 18.8188 2.7014 18.111 2.32897 17.1875C1.7774 15.8047 1.90469 12.5235 1.90469 10.9953C1.90469 9.46721 1.78212 6.18127 2.32897 4.80315C2.69669 3.8844 3.40855 3.17659 4.33726 2.80627C5.72797 2.25784 9.02798 2.3844 10.5648 2.3844C12.1017 2.3844 15.4064 2.26252 16.7924 2.80627C17.7164 3.1719 18.4283 3.87971 18.8007 4.80315C19.3523 6.18596 19.225 9.46721 19.225 10.9953C19.225 12.5235 19.3523 15.8094 18.8007 17.1875Z" fill="black"></path>
        </svg>
      )}
      {content.platform === 'tiktok' && (
        <svg width="22" height="22" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" /></svg>
      )}
      {content.platform === 'twitter' && (
        <svg width="22" height="22" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_828_2466)"><path d="M8.91957 23.7072C18.5848 23.7072 23.7989 15.9496 23.7989 9.20937C23.7989 9.0822 23.7989 8.70068 23.7989 8.5735C24.8163 7.81046 25.7065 6.92024 26.4696 5.90285C25.4522 6.28437 24.562 6.53872 23.4174 6.6659C24.4348 6.03003 25.325 5.01264 25.7065 3.86807C24.6891 4.37677 23.5446 4.88546 22.4 5.13981C21.3826 3.99524 19.9837 3.35938 18.5848 3.35938C15.6598 3.35938 13.3707 5.6485 13.3707 8.44633C13.3707 8.82785 13.3707 9.20937 13.4978 9.5909C9.04675 9.46372 5.23153 7.30177 2.68805 4.24959C2.17935 5.01264 1.92501 5.90285 1.92501 6.92024C1.92501 8.70068 2.9424 10.2268 4.21414 11.2442C3.32392 11.2442 2.56088 10.9898 1.92501 10.6083C1.92501 10.6083 1.92501 10.6083 1.92501 10.7355C1.92501 13.1518 3.83261 15.3137 6.12175 15.8224C5.61305 15.9496 5.23153 15.9496 4.72283 15.9496C4.46848 15.9496 4.08696 15.9496 3.70544 15.8224C4.34131 17.8572 6.37609 19.3833 8.53805 19.3833C6.75762 20.655 4.46848 21.5452 2.05218 21.5452C1.67066 21.5452 1.16196 21.5452 0.780441 21.4181C3.19675 22.817 5.99457 23.7072 8.91957 23.7072Z" fill="black"/></g><defs><clipPath id="clip0_828_2466"><rect width="26" height="26" fill="white" transform="translate(0.625 0.533203)"/></clipPath></defs>
          </svg>
      )}
      {content.platform === 'discord' && (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 71 55" fill="none">
          <g clip-path="url(#clip0)"><path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" fill="#23272A"/></g><defs><clipPath id="clip0"><rect width="71" height="55" fill="white"/></clipPath></defs>
        </svg>
      )}
    </a>
  )
}

export default SocialLink
