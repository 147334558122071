import * as React from "react"
import { useStore } from "src/state"
import Navigation from "components/navigation"
import Hero from "components/hero"
import GameHero from "components/game-hero"
import Booth from "components/booth"
import Prizing from "components/prizing"
import Talent from "components/talent"
import Rules from "components/rules"

const IndexPage = () => {
  const { content } = useStore()
  return content && (
    <main>
      <title>{content.general.siteName}</title>
      <Navigation content={content.navigation}></Navigation>
      <div className="sections">
        <Hero content={content.hero}></Hero>
        <GameHero content={content.robloxHero} section="roblox"></GameHero>
        <Booth content={content.robloxConcert} type="concert"></Booth>
        <Prizing content={content.robloxPrizes}></Prizing>
        <Talent content={content.robloxTalent}></Talent>
        <Rules content={content.robloxRules} section="roblox"></Rules>
        <div className="inverted-background">
          <GameHero content={content.zepetoHero} section="zepeto"></GameHero>
          <Booth content={content.zepetoVideoBooth} type="photo"></Booth>
          <Talent content={content.zepetoTalent}></Talent>
          <Rules content={content.zepetoRules} section="zepeto"></Rules>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
