import React from "react"
import c from 'classnames'
import SocialLink from "components/social-link"

const Talent = ({ content }) => {
  return (
    <section className="talent">

      <h2>{content.title}</h2>

      {content.talent && (
        <div className="container">
          {content.talent.map(({ image, name, social }, i) => (
            <div key={i} className="talent">
              <img src={image} loading="lazy" alt="talent" />
              <div className="name">{name}</div>
              <div className="social">
                {social && social.length && social.map((socialContent, i) => (
                  <SocialLink content={socialContent} key={i} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {content.talentSection && (
        <div className="roblox-container">
          {content.talentSection.map(({ title, talent }, i) => (
            <section key={i}>
              <h3>{title}</h3>
              <div className={c('has-rule', `section-${i}`)}>
                <div className="flex">
                  {talent.map(({ image, name, social }, i) => (
                    <div key={i} className="talent">
                      <img src={image} loading="lazy" alt="talent" />
                      <div className="name">{name}</div>
                      {social && social.length > 0 && (
                        <div className="social">
                          {social.map((socialContent, i) => (
                            <SocialLink content={socialContent} key={i} />
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </div>
      )}

    </section>
  )
}

export default Talent
