import React, { useEffect, useRef } from "react"
import { useStore } from "src/state"

const Rules = ({ content, section }) => {
  const { setCurrentSection } = useStore()
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries[0] && entries[0].isIntersecting && setCurrentSection(section, ref.current)
    }, { threshold: 0.6 })
    const currentRef = ref.current
    currentRef && observer.observe(currentRef)
    return () => (currentRef && observer.unobserve(currentRef))
  }, [ref, setCurrentSection, section])

  return (
    <section className="rules" ref={ref}>
      <h2>{content.title}</h2>
      <div className="container">
        {content.rules && content.rules.map(({ name, url }, i) => (
          <a key={i} className="box" target="_blank" href={url} rel="noreferrer">
            {name}
          </a>
        ))}
      </div>
    </section>
  )
}

export default Rules
