import React, { useEffect, useRef } from "react"
import { useStore } from "src/state"

const Hero = ({ content }) => {
  const { setCurrentSection } = useStore()
  const ref = useRef(null)

  useEffect(() => {
    const currentRef = ref.current
    const observer = new IntersectionObserver((entries) => {
      entries[0] && entries[0].isIntersecting && setCurrentSection('crocsworld')
    }, { threshold: 0.4 })
    currentRef && observer.observe(currentRef)
    return () => (currentRef && observer.unobserve(currentRef))
  }, [ref, setCurrentSection])

  let tickerContent = Array(10).fill(null).map(() => content.tickerText);

  return (
    <section className="hero crocsworld" ref={ref}>
      <div className="container">
        <img src={content.image} alt="hero" className="hero-image" />
        <img src={content.logo} alt="Logo" className="floating-logo" />
        <div className="content">
          <img src={content.logo} alt="Logo" className="floating-logo" />
          <h1>{content.title}</h1>
          <p>{content.copy}</p>
        </div>
      </div>

      <div className="ticker">
        <div>
          {tickerContent && tickerContent.map((message, i) => (
            <div key={i}>{message}</div>
          ))}
        </div>
      </div>

    </section>
  )
}

export default Hero
